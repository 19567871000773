import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Provider } from 'react-redux'
import store from './store'

import './scss/style.scss'
import './scss/core/_dropdown-menu-right.scss'
import 'react-toastify/dist/ReactToastify.css';
import { icons } from './app/assets/icons'
import OauthProtect from './app/views/components/OauthProtect'
import App from './app/views/App'

React.icons = icons

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <OauthProtect>
            <Router>
                <App />
            </Router>
        </OauthProtect>
        <ToastContainer />
    </Provider>
)


